/*

Timeline code taken from:
https://mdbootstrap.com/docs/standard/extended/timeline/

*/

import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";

import { Link } from "react-router-dom";

import Tag from "./Tag";

import './Timeline.css'

import { ReactComponent as ProfessionalIcon } from "./icons/building.svg";
import { ReactComponent as ResearchIcon } from "./icons/search-alt.svg";
import { ReactComponent as TeachingIcon } from "./icons/chalkboard-user.svg";

import { ReactComponent as EYLogo } from "./icons/logos/EY-logo.svg";
import { ReactComponent as LehighLogo } from "./icons/logos/Lehigh-logos.svg";

/*
TODO:
Create meta data tags for each experience, and add UI filter to select which experiences to show
(hide html elements that don't match the listed tags responsively)

*/


function TimeLine() {
  return (
    <section className="py-5">
      <ul className="timeline-with-icons">
        <li className="timeline-item mb-5">
          <span className="timeline-icon">
            <EYLogo className="experience-logo" />
          </span>
          <h5 className="fw-bold">
            Ernst & Young LLP</h5>
          <p className="mb-2">FSO Staff Technology Consultant</p>
          <p className="text-muted mb-2 fw-bold">July 2022 – Present</p>
          <p className="text-muted">
            I design software architecture for tech transformation clients and develop proof of concepts internally using React, Python, SQL, and Snowflake for EY.
          </p>
          <p>To learn more about EY, please <a href="https://www.ey.com/en_us">click here</a>.</p>
          <p>Skills:{' '}{["SQL", "Python", "Grafana", "Plotly.js", "Design Thinking", "Presenting", "Git", "JavaScript", "React", "Observability", "OpenShift","AWS"].map((tag) => <Tag name={tag} />)}</p>
        </li>

        <li className="timeline-item mb-5">

          <span className="timeline-icon">
            <ProfessionalIcon className="timeline-icon-svgs" />
          </span>
          <h5 className="fw-bold">Foli-Q</h5>
          <p className="mb-2">Data Scientist </p>
          <p className="text-muted mb-2 fw-bold">May 2023 – July 2023</p>
          <p className="text-muted">
            I led the development of Foli-Q’s machine-learning infrastructure and provided technical consultation. While supervising 2 interns, I built an end-to-end pipeline for scraping, processing, persisting, and extracting data at scale. Each week, I presented progress reports covering high-level functionality directly to the CEO. I successfully ended my contract with all objectives met and continue to provide consultation on an ad-hoc basis.
          </p>
          <p>To learn more about the startup, please <a href="https://foliq.me/">click here</a>.</p>
          <p>Skills:{' '}{["PyTorch", "BeautifulSoup", "Python", "SQL", "PySpark", "Presenting", "Git", "Docker"].map((tag) => <Tag name={tag} />)}</p>
        </li>

        <li className="timeline-item mb-5">

          <span className="timeline-icon">
            <LehighLogo className="experience-logo" />
          </span>
          <h5 className="fw-bold">Lehigh University</h5>
          <p className="mb-2">Bachelors of Science Computer Science </p>
          <p className="text-muted mb-2 fw-bold">August 2018 – May 2022</p>
          <p className="text-muted">
            Graduating with High Honors (3.79 GPA & 3.97 Major GPA), I recieved
            my degree in CS. Additionally, I completed 2 minors in data science
            and entrepreneurship while accruing ~2 years of experience in research and ~4 years
            experience with small and medium-sized businesses. My merit in
            academics and entrepreneurship is recognized by the 3 honor societies I was
            invited to join: Tau Beta Pi, engineering honors society; Upsilon Pi Epsilon,
            computing and information disciplines honors society; and Baker Entrepreneurial
            Fellow, distinguished group recognized by Lehigh's Baker Institute. Meanwhile,
            I partipated in the residence hall council for 2 years, worked as a teaching
            assistant for 3 years, and lead various professional development events.
          </p>
          <p>To learn more about the program details, please <a href="https://catalog.lehigh.edu/coursesprogramsandcurricula/engineeringandappliedscience/computerscienceandengineering/#undergraduatetext">click here</a>.</p>
        </li>
        <li className="timeline-item mb-5">
          <span className="timeline-icon">
            <ResearchIcon className="timeline-icon-svgs" />
          </span>
          <h5 className="fw-bold">Nano-Human Interfaces Laboratory</h5>
          <p className="mb-2">Machine Learning Research Assistant</p>
          <p className="text-muted mb-2 fw-bold">May 2021 – May 2022</p>
          <p className="text-muted">
            Collaborating in interdisciplinary research, I set out to predict anomalies in crystalline material microstructures using deep learning algorithms. Every week, I provided progress reports to Dr. Martin Harmer, NHI Director; Dr. Brian Chen, my principal investigator; Dr. Lifang He, NHI machine learning specialist; and other colleagues. My main contribution was the develpment of a graph convolutional recurrent neural network model.
          </p>
          <p>To learn more about the project, please <Link to="/projects">click here</Link>.</p>
          <p>To learn more about the lab, please <a href="https://nhi.lehigh.edu/">click here</a>.</p>
          <p>Skills:{' '}{["PyTorch", "NumPy", "Scikit Learn", "Python", "NetworkX", "Presenting", "Git"].map((tag) => <Tag name={tag} />)}</p>
        </li>
        <li className="timeline-item mb-5">
          <span className="timeline-icon">
            <LehighLogo className="experience-logo" />
          </span>
          <h5 className="fw-bold">Lehigh Silicon Valley</h5>
          <p className="mb-2">Selected Participant </p>
          <p className="text-muted mb-2 fw-bold">January 2022</p>
          <p className="text-muted">
            Lehigh Silicon Valley is the Baker Institute's flagship week-long winter term program immersing students in the Bay Area entrepreneurial ecosystem. During the program, I delivered use case presentations to various startup CEOs, such as vArmour and Pipcorn. Additionally, I had the pleasure of speaking with VMware and Adobe Inc. executive management. Most notably, I had the opportunity to speak with Adobe Inc. CMO Ann Lewnes.
          </p>
          <li>To learn more about the program details, please <a href="https://www.lehighbakerinstitute.com/lsv">click here</a>.</li>
          <li>To learn more about my cohort, please <a href="https://www.lehighbakerinstitute.com/post/meet-the-baker-institute-s-lehighsiliconvalley-2022-cohort">click here</a>.</li>
        </li>
        <li className="timeline-item mb-5">

          <span className="timeline-icon">
            <TeachingIcon className="timeline-icon-svgs" />
          </span>
          <h5 className="fw-bold">Lehigh University Computer Science & Engineering Department</h5>
          <p className="mb-2">Teaching Assistant: Software Eneingeering course (CSE216)</p>
          <p className="text-muted mb-2 fw-bold">January 2021 – May 2022</p>
          <p className="text-muted">
            For 3 semesters, I assisted in teaching the software engineering course at Lehigh University. Each week, I mentored 5 teams on full-stack applications. I helped facilitate recitations consisting of ~75 students on design patterns. As for my biggest contribution, I worked with Professor Liang Cheng and Professor Mark Erle to help modernize the curriculum which had been over 5 years old.
          </p>
          <p>For the course description, please <a href="https://engineering.lehigh.edu/cse/academics/course-index/cse-216-software-engineering-3">click here</a>.</p>
          <p>Skills:{' '}{["SQL", "Grading", "Presenting", "Git", "JavaScript", "Java", "TypeScript", "React"].map((tag) => <Tag name={tag} />)}</p>
        </li>
        <li className="timeline-item mb-5">

          <span className="timeline-icon">
            <LehighLogo className="experience-logo" />
          </span>
          <h5 className="fw-bold">Captstone: Smart COPD Patient Monitoring System</h5>
          <p className="mb-2">Lead Developer</p>
          <p className="text-muted mb-2 fw-bold">January 2021 - December 2021</p>
          <p className="text-muted">
          As a student sponsor, I lead a team of computer science students in the development of a chronic obstructive pulmonary disease (COPD) patient monitoring system: a web application that utilized commercial off-the-shelf devices (COTS) to measure biodata from individuals suffering from COPD and applied a decision tree supervised machine learning algorithm to classify the risk of COPD patients returning to the hospital within 30 days. As the team's lead developer, I gained experience with Vue, Java Spark, PostgreSQL, Python machine learning libraries, leading a team of 4 undergraduate students, and acting as a liaison between other universities involved with our project (Worcester Polytechnic Institute and University of Toledo).
          </p>
          <p>To learn more, please <Link to="/projects">click here</Link>.</p>
          <p>Skills:{' '}{["Scikit Learn","Python","SQL", "Design Thinking", "Presenting", "Git", "C++","Vue","JavaScript", "Java", "TypeScript", "React"].map((tag) => <Tag name={tag} />)}</p>
        </li>
        <li className="timeline-item mb-5">

          <span className="timeline-icon">
            <ResearchIcon className="timeline-icon-svgs" />
          </span>
          <h5 className="fw-bold">Learning and Optimization on Networks and Graphs Laboratory</h5>
          <p className="mb-2">Research Assistant</p>
          <p className="text-muted mb-2 fw-bold">April 2020 – May 2021</p>
          <p className="text-muted">
            While investigating pervasive solutions for road surveillance, I developed 10 novel game theoretical incentive mechanisms to promote user participation. Using C++ and Python, I generated over 10 million simulations and visualized the results in Matplotlib. Every week, I would present my work to Professor Liang Cheng and his lab to collect feedback. After augmenting my findings with 2 deep learning algorithms, I was published as 1st author in a textbook chapter.
          </p>
          <li>To view the published textbook chapter, please <a href="https://www.degruyter.com/document/doi/10.1515/9783110785982-025/html?lang=en">click here</a></li>
          <p>To learn more about the lab (now known as CHENGS), please <a href="https://liangcheng.info/long-lab/">click here</a></p>
          <p>Skills:{' '}{["Matplotlib", "Python", "Matlab", "Git", "C++", "LaTeX"].map((tag) => <Tag name={tag} />)}</p>
        </li>
        <li className="timeline-item mb-5">

          <span className="timeline-icon">
            <LehighLogo className="experience-logo" />
          </span>
          <h5 className="fw-bold">Advanced Technology for Large Structural Systems Engineering Research Center</h5>
          <p className="mb-2">Research Experience for Undergraduates (REU)</p>
          <p className="text-muted mb-2 fw-bold">May 2020 – August 2020</p>
          <p className="text-muted">
            As part of my research experience for undergraduates, I helped create a distributed sensing platform where users are incentivized to collect accelerometer and camera data. Additionally, I helped developed an Android application that used a MobileNet SSD image classifier to automate road flagging. Twice a week, I presented my progress to REU program leaders and other REU students. Finally, I presented and published my findings in the CIAMTIS conference as a technical report.
          </p>
          <li>To learn more about the program details, please <a href="https://atlss.lehigh.edu/outreach/research-experience-undergraduates-reu">click here</a>.</li>
          <p>To learn more about the technical report, please <a href="https://rosap.ntl.bts.gov/view/dot/56366">click here</a>.</p>
          <p>Skills:{' '}{["Matlab", "Publishing", "Presenting", "Git", "C++"].map((tag) => <Tag name={tag} />)}</p>
        </li>
        <li className="timeline-item mb-5">

          <span className="timeline-icon">
            <TeachingIcon className="timeline-icon-svgs" />
          </span>
          <h5 className="fw-bold">Lehigh University P.C Rossin College of Engineering & Applied Science</h5>
          <p className="mb-2">Teaching Assistant: Applied Engineering Computer Methods Course (ENGR010)</p>
          <p className="text-muted mb-2 fw-bold">August 2019 – May 2020</p>
          <p className="text-muted">
            For 2 semesters, I assisted in teaching an applied engineering course for undergraduate engineering students looking to learn Arduino and Matlab. Each week I would grade 80 code submissions and every other week I taught recitations on digital/analog IO devices for ~40 students. Most notably, I collaborated with the course instructors on designing the final projects and judged 20 final project submissions for water pumps and assembly lines.
          </p>
          <p>For the course description, please <a href="https://catalog.lehigh.edu/courselisting/engr/">click here</a>.</p>
          <p>Skills:{' '}{["Matlab", "Grading", "Presenting", "Arduino"].map((tag) => <Tag name={tag} />)}</p>
        </li>
        <li className="timeline-item mb-5">

          <span className="timeline-icon">
            <LehighLogo className="experience-logo" />
          </span>
          <h5 className="fw-bold">The Hatchery</h5>
          <p className="mb-2">Selected Participant</p>
          <p className="text-muted mb-2 fw-bold">May 2019 – August 2019</p>
          <p className="text-muted">
            The Hatchery is a 3 month long idea accelerometer focused on providing participates resouces for building their startup ideas. In my case, I developed a startup called TrussNet. TrussNet was a solution to the problem many undergraduates face, "how do I pick the right major for me." The Java based application factored in a student's career interests and hobbies to rank potential majors. During the program, TrussNet won 1st place in 2 competitions and was awarded over $600 for development.
          </p>
          <li>To learn more about the program details, please <a href="https://www.lehighbakerinstitute.com/hatchery">click here</a>.</li>
          <li>To learn more about my cohort, please <a href="https://www.lehighbakerinstitute.com/hatchery-projects19">click here</a>.</li>
          <p>To see my thoughts on the program, please <a href="https://www.lehighbakerinstitute.com/post/saying-yes-to-any-opportunity">click here</a>.</p>
          <p>Skills:{' '}{["Design Thinking", "Presenting", "Startup", "Java"].map((tag) => <Tag name={tag} />)}</p>
        </li>
      </ul>
    </section>
  );
}

export default TimeLine;