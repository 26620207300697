// Bio.js
import React from 'react';

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import {ReactComponent as Target} from '../components/icons/bullseye-arrow.svg';

import './Background.css'

const Background = () => {
    return (
        <div className="d-flex">
            <Card className='sliderbg' style={{ padding: "10px", width: '30rem', backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
                <Image rounded src="/portugal.png" width="250px" style={{ alignSelf: "center" }} />
                <hr></hr>
                <h1 style={{ alignSelf: "center" }}>Lagos, Portugal <a href='https://www.google.com/maps/@37.0877953,-8.6689031,18.33z?entry=ttu'><Target className='icon'/></a></h1>
                <p>
                    In the Fall of 2023, I had the pleasure of visiting Portugal and its beautiful cities: Lisbon, Porto, Douro Valley, and Lagos. For anyone thinking of visting, I strongly recommend you go to the major cities (Lisbon and Porto). They are affordable, friendly to all, and gorgeous. I especially enjoyed walking through the many alleys in Porto. That being said, prepare for cardio... the hills will test you.
                </p>
                <p>
                    Food I tried:
                    <ul><b>Bacalhau</b> (cod fish) : the national dish of portugal which can be served in a number of ways - all worth trying.</ul>
                    <ul><b>Pasteis de Nata</b> (egg custard tart) : one of the BEST pastries I've had, second only to my culture's Pastelitos de Guayaba - but I may be biased.</ul>
                    
                </p>
            </Card>
        </div>
    );
};

export default Background;