import React from "react";
import TimeLine from '../components/Timeline';

import Card from 'react-bootstrap/Card';

import { ReactComponent as PDF } from '../components/icons/acrobat.svg';

import { Link } from 'react-router-dom';

import './Experiences.css'

/* 
TODO: future, create a pie chart with my image in the center (and the data on the edge of the round image. shows what percentage research, what percentage entrepreneurship, what percentage)
*/

function Experiences() {
  return (
    <div className="d-flex justify-content-center" style={{ marginTop: "12px", marginBottom: "12px" }}>
      <Card className="slider" style={{ padding: "18px", width: '31rem', backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
        <div style={{ alignSelf: "center", textAlign: "center"}}>
          <h2>Experiences</h2>
          <p>
            <Link to="https://drive.google.com/file/d/1xygJjIkD2S79yGy-wO5vncyqmj3seMsd/view?usp=sharing">
              <PDF className="d-inline-block icon" style={{ fill: "#ff0000" }} />
              Resume
            </Link> | {' '}
            <Link to="https://drive.google.com/file/d/1z0G3Edj7NwNkVOY2S-N0BdxyWPIwwzcH/view?usp=drive_link">
              <PDF className="d-inline-block icon" style={{ fill: "#ff0000" }} />
              CV
            </Link>
          </p>
        </div>
        <hr />
        <p>Below you will find long descriptions focused on my key experiences (work, research, leadership, and more) since 2019. </p>
        <p>If you are interested in a short form description of my accomplishments, please view my resume and CV above.</p>
        <TimeLine />

      </Card>

    </div>
  );
}

export default Experiences;