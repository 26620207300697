// Navigation.js
import React, { useState } from 'react';

import Badge from 'react-bootstrap/Badge';

import "./Tag.css"

/*
TODO: create a color hashing function instead of manually setting color...
*/

let tiers = {
    ds_high : "#cc0000",
    ds_med : "#ff1a1a",
    ds_low : "#ff8080",
    neutral : "#ffd699",
    swe_low : "#80b3ff",
    swe_med : "#3385ff",
    swe_high : "#0066ff"
}

let available_tags = {
    "PyTorch" : tiers.ds_high,
    "BeautifulSoup" : tiers.ds_high,
    "NumPy" : tiers.ds_high,
    "Scikit Learn" : tiers.ds_high,
    "Matplotlib" : tiers.ds_high,
    "Python" : tiers.ds_med,
    "SQL" : tiers.ds_med,
    "Matlab" : tiers.ds_med,
    "R" : tiers.ds_med,
    "PySpark" : tiers.ds_low,
    "Grafana" : tiers.ds_low,
    "Plotly.js" : tiers.ds_low,
    "NetworkX" : tiers.ds_low,
    "Grading" : tiers.neutral,
    "Publishing" : tiers.neutral,
    "Presenting" : tiers.neutral,
    "Design Thinking" : tiers.neutral,
    "Startup" : tiers.neutral,
    "Arduino" : tiers.swe_low,
    "LaTeX" : tiers.swe_low,
    "Git" : tiers.swe_low,
    "C++" : tiers.swe_low,
    "Vue" : tiers.swe_low,
    "JavaScript" : tiers.swe_med,
    "Java" : tiers.swe_med,
    "Docker" : tiers.swe_med,
    "Proxmox" : tiers.swe_med,
    "Bootstrap" : tiers.swe_med,
    "TypeScript" : tiers.swe_med,
    "React" : tiers.swe_med,
    "Jenkins" : tiers.swe_high,
    "Kubernetes" : tiers.swe_high,
    "GitLab" : tiers.swe_high,
    "OpenShift" : tiers.swe_high,
    "Observability" : tiers.swe_high,
    "AWS" : tiers.swe_high,
    "NGINX" : tiers.swe_high
}

function Tag(props) {


    return (
        <span>
        <span className='tag' style={{background:available_tags[props.name], color: available_tags[props.name] === tiers.neutral ? "black" : "white"}}>
            {props.name}
        </span> {" "}
        </span>
    );
};

export default Tag;

