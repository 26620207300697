import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';

import './Bio.css'

function Bio() {
  return (
    <div className="d-flex justify-content-center" style={{ marginTop: "12px", marginBottom: "12px" }}>
      <Card className="slider" style={{ padding: "10px", width: '55rem', backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>

        <Container style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

          <Image rounded src="/HeadshotFormal.jpg" style={{ width: "50%", marginRight: "5px" }} />

          <div style={{ textAlign: "center" }}>
            <h1>
              Maximillian Machado
            </h1>
            <ul>
              Bachelors of Science in Computer Science
            </ul>
            <ul>
              Concentration in Data Science and Entrepreneurship
            </ul>
            <ul>
              @ Lehigh University
            </ul>

          </div>

        </Container>
        <hr />
        <div>
          <h3>Education</h3>
          <p>
            Maximillian successfully completed his bachelors of science in computer science at
            Lehigh University. There, he participated in various entrepreneurial programs and research experiences
            while pursing his minors in entrepreneurship and data science.
          </p>
          <p>
            His entrepreneurial contributions stem from his involvement with Lehigh's hub for innovation - the Baker
            Institute. From assisting in the Baker Institute's StartItUp pre-orientation program
            to participating in Lehigh's Silicon Valley program, he gravitates towards innovative
            environments and thrives when learning opportunities are present.
          </p>
          <p>
            His research contributions were primary focused on interdisciplinary work that applies
            advanced computer science concepts, such as deep learning and crowdsensing, to
            great impact areas. Most notably, he was involved with the Nano-Human Interfaces
            Lab, the LONG Lab, and the Advanced Technology for Large Strucutral Systems REU.
            While working at these labs, he developed deep learning models for material
            microstructure abnormality prediction, novel game theoretical incentive mechanisms,
            and a road damage crowdsensing simulation.
          </p>
          <h3 >Interests</h3>
          <p>
            Maximillian is expressly interested in Interpretable Machine Learning.
            With the proliferation of large language models, generative adversarial
            networks, and other deep learning models, he wants to challenge the 'black box'
            notion of deep learning models. By studying existing methods of deep learning
            feature importance and frameworks for interpretablity, Maximillian's goal
            is to enable the performance of deep learning without the significant risk
            that comes with its current black box state.</p>
          <h3>Honors & Awards</h3>
          <p>
            <li>
              Lehigh University Baker Fellow (2020), an entrepreneurial distinction given to 20 individuals across Lehigh University's graduate and undergraduate population;
            </li>
            <li>
              Winner of the Baker Institute’s Eureka! Ventures Pitch Competition, Stage 1 (June 2019) and Stage 2 (July 2019);
            </li>
            <li>
              Tau Beta Pi Engineering Honor Society;
            </li>
            <li>
              Upsilon Pi Epsilon Computing and Information Disciplines Honor Society;
            </li>
            <li>
              Dean’s List;
            </li>
            <li>
              Graduation with Magna Cum Laude (high honors).
            </li>
          </p>

          <h3>
            Publications
          </h3>
          <p>
            <li>
              Embedded Crowdsensing for Pavement Monitoring and its Incentive Mechanisms (<a href="https://doi.org/10.1515/9783110785982-025">textbook chapter</a>)
            </li>
            <li>
              Road Pavement Condition Monitoring by Embedded Crowdsensing (<a href="https://rosap.ntl.bts.gov/view/dot/56366">technical report</a>)
            </li>
          </p>

          <h3>Relevant Courses</h3>
          <p>
            <Accordion defaultActiveKey={['0']} >
              <Accordion.Item eventKey="0">
                <Accordion.Header>CSE 347 - Data Mining</Accordion.Header>
                <Accordion.Body>
                  Overview of modern data mining techniques: data cleaning; attribute and subset
                  selection; model construction, evaluation and application. Fundamental mathematics
                  and algorithms for decision trees, covering algorithms, association mining,
                  statistical modeling, linear models, neural networks, instance-based learning
                  and clustering covered. Practical design, implementation, application and
                  evaluation of data mining techniques in class projects.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>CSE 327 - Artificial Intelligence Theory & Practice</Accordion.Header>
                <Accordion.Body>
                  Introduction to the field of artificial intelligence: Problem solving,
                  knowledge representation, reasoning, planning and machine learning. Use
                  of AI systems or languages. Advanced topics such as natural language
                  processing, vision, robotics, and uncertainty.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>CSE 340 - Design & Analysis of Algorithms</Accordion.Header>
                <Accordion.Body>
                  Algorithms for searching, sorting, manipulating graphs and trees, finding
                  shortest paths and minimum spanning trees, scheduling tasks, etc.: proofs
                  of their correctness and analysis of their asymptotic runtime and memory
                  demands. Designing algorithms: recursion, divide-and-conquer, greediness,
                  dynamic programming. Limits on algorithm efficiency using elementary
                  NP-completeness theory.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>CSE 341 - Database Systems</Accordion.Header>
                <Accordion.Body>
                  Design of large databases; normalization; query languages (including SQL); 
                  transaction-processing protocols; query optimization; performance tuning; 
                  distributed systems.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>CSE 264 - Web Systems</Accordion.Header>
                <Accordion.Body>
                Practical experience in designing and implementing modern Web applications. 
                Concepts, tools, and techniques, including: HTTP, HTML, CSS, DOM, JavaScript, 
                Ajax, PHP, graphic design principles, mobile web development.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>ECO 358 - Game Theory</Accordion.Header>
                <Accordion.Body>
                This course introduces students to a set of tools that economists, among others, 
                use to analyze strategic interactions among individuals, firms, nations, etc. 
                The coverage of theories begins with single-person decision-making and extends 
                to games (multi-person interactive decision-making) under various strategic and 
                informational environments. Theories will be supplemented with a variety of 
                applications, which include competitive pricing in oligopolistic markets, 
                tragedy of the commons, bidding behavior in auctions, bargaining, voting and 
                electoral competition, and strategic use and transmission of information.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>ENTP 304 - Technology & Software Ventures</Accordion.Header>
                <Accordion.Body>
                Designed from the perspective of functional leaders, course provides a holistic 
                perspective of developing successful software ventures across various industries 
                in an interdisciplinary and experiential environment. Students develop a 
                software-oriented idea, concurrent with module delivery containing best practices, 
                case studies, and subject-matter experts. Examines business model fundamentals, 
                customer discovery, translating requirements to a minimum viable product, agile 
                development, user acquisition, and traction.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </p>
          <h3>Favorites</h3>
          <p>
            Beyond the marvel that is computer science and interdisciplinary research, Maximillian
            is also a big fan of things like movies and food. Here are some of his all time favorites:
            <li>
              Movie: Interstellar
            </li>
            <li>
              Comfort dish: gnocchi and tortellini equally
            </li>
            <li>
              Cuisine: Cuban, Mediterranean, and Indian equally (changes all all the time)
            </li>
            
          </p>
        </div>

      </Card>

    </div>
  );
}

export default Bio;