// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Bio from './pages/Bio'; // Import your section components
import Experiences from './pages/Experiences';
import Projects from './pages/Projects';
import Background from './pages/Background';
import Navigation from './components/Navigation';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './pages/Home';

import { Image } from 'react-bootstrap';


const nav_bar_hieght = 100

const App = () => {
  return (
    <Router>
      {/* the double navigation bar is just a simple trick to keep the Navbar fixed at top with Z-axis problems */}
      <Navigation />
      <Navigation isFixed="top" />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bio" element={<Bio />} />
        <Route path="/experiences" element={<Experiences />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/background" element={<Background />} />
      </Routes>
    </Router>
  );
};

export default App;