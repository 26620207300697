import React, { useEffect, useState } from "react";
import { MDBContainer } from "mdb-react-ui-kit";


import Card from 'react-bootstrap/Card';

// styling
import './Showcase.css'
import { Container, Image } from "react-bootstrap";

import Tag from "./Tag";

let project_data = [
    {
        title: "Home Lab",
        date: "January 2023 - Present",
        image: "/proxmox.png",
        image_layout: "vertical",
        description: "I maintained a Proxmox hypervisor, which hosted a variety of services including HTTPS web services, TCP gaming servers, Discord bots, and IoT devices. In addition, it also provided an environment for code development tools. These were all hosted in isolated virtual infrastructures that complied with IEEE 802.1Q. To ensure secure routing of SSL-encrypted web service traffic, I utilized reverse proxies, Dynamic DNS, and VPN interfaces.",
        skills: ["Python","SQL","Grafana","Git","JavaScript","Docker","Proxmox","Kubernetes","GitLab","NGINX"]
    },
    {
        title: "Grain-Boundary Graph Convolutional Recurrent Neural Network Model",
        date: "May 2021 - May 2022",
        image: "/NHI.png",
        image_layout: "vertical",
        description: "In my research at the Nano-Human Interfaces Lab, I explored material microstructures while studying challenges in grain-boundary analysis. My focus was on predicting ‘abnormal’ grain growth, which could significantly alter the way we understand material properties. I used a combination of spatial and temporal neural networks to predict when these abnormalities would occur based on previous sequences. Specifically, I developed a pipelined Graph Convolutional Network (GCN) and Recurrent Neural Network (RNN) to analyze Monte Carlo simulations of a Grain Boundary Potts model. This allowed me to predict when grain abnormality would occur in the material.",
        links: [
            {
                pretext: "To learn more about the lab",
                path: "https://nhi.lehigh.edu/"
            },
        ],
        skills: ["PyTorch", "NumPy", "Scikit Learn", "Python", "NetworkX", "Presenting", "Git"]
    },
    {
        title: "Smart COPD Patient Monitoring System",
        date: "January 2021 - December 2021",
        image: "/PatientUpload.png",
        image_layout: "vertical",
        description: "I designed the Smart COPD Patient Monitoring System, a web app that links chronic obstructive pulmonary disease (COPD) patients with healthcare providers and predicts the risk of a patient returning to the hospital. The system features Google OAuth 2.0, a Vue.js frontend, a Java backend, a PostgreSQL DB, and a Python Machine Learning module with a decision tree model. I architected the system, managed developers, facilitated communication between advisers, and developed the full stack.",
        links: [
            {
                pretext: "To see the demo (ML functionality disabled)",
                path: "https://smartcopd.mkmachado.com"
            },
        ],
        skills: ["Scikit Learn","Python","SQL", "Design Thinking", "Presenting", "Git", "C++","Vue","JavaScript", "Java", "TypeScript", "React"]
    },
    {
        title: "Embedded Crowdsensing for Pavement Monitoring and Its Incentive Mechanisms",
        date: "April 2020 – May 2021",
        image: "/longlab.png",
        image_layout: "vertical",
        description: "I had the honor of being published as the first author for my work in developing ten novel game theoretical incentive mechanisms in crowdsensing platforms. My research included two use cases for supervised learning in budget prediction and one use case for an unsupervised learning algorithm for path optimization. To evaluate each incentive mechanism, I developed a crowdsensing simulation in C++. This allowed me to test a total of 11 million scenarios. After running these simulations, I visualized and analyzed the evaluation metrics using the Matplotlib module in Python.",
        links: [
            {
                pretext: "To view the published textbook chapter",
                path: "https://www.degruyter.com/document/doi/10.1515/9783110785982-025/html?lang=en"
            },
        ],
        skills: ["Matplotlib", "Python", "Matlab", "Git", "C++", "LaTeX"]
    },
    {
        title: "QoLab",
        date: "September 2020",
        image: "/qolab-master-photo.png",
        image_layout: "vertical",
        description: "My peers and I participated in the 2020 MIT Hackathon to produce QoLab: the Lo-fi beats study application that enables individuals to create music sessions with their friends to conquer work. The Application is built on the Django framework.",
        links: [
            {
                pretext: "To see the demo",
                path: "https://qolab.mkmachado.com"
            },
        ],
        skills: ["Python","SQL","Presenting","Design Thinking","Git","Vue"]
    },
    {
        title: "Road Pavement Condition Monitoring by Embedded Crowdsensing",
        date: "May 2020 - August 2020",
        image: "REUdata.png",
        image_layout: "vertical",
        description: "I helped develop an Android mobile application that crowdsourced road pavement data using camera and accelerometer data. The app utilized a MobileNet SSD image classifier to identify eight types of road degradation at various coordinates with a confidence level of 60%. Additionally, I contributed to a technical report that was published and had the opportunity to present the project at the CIAMTIS conference.",
        links: [
            {
                pretext: "To view the published technical report",
                path: "https://rosap.ntl.bts.gov/view/dot/56366"
            },
        ],
        skills: ["Matlab", "Publishing", "Presenting", "Git", "C++"]
    },
    {
        title: "TrussNet",
        date: "May 2019 - August 2019",
        image: "/hatcherydemoday.jpg",
        image_layout: "vertical",
        description: "As a co-founder of TrussNet, I helped create an application that guides college students in course selection based on their unique interests. Our tool won the Baker Institute’s Eureka! Ventures Pitch Competition in both Stage 1 and 2 in 2019. My responsibilities included customer discovery, fundraising, assisting in prototype development, and presenting to potential stakeholders.",
        links: [

        ],
        skills: ["Design Thinking", "Presenting", "Startup", "Java"]
    },
];

function horizontal_layout(image, description) {
    return (
        <Container style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Image src={image} width="50%" style={{paddingRight:"5px"}} />
            <p>{description}</p>
        </Container>
    );
};

function vertical_layout(image, description) {
    return (
        <Container>
            <Image src={image} width="100%" />
            <p>{description}</p>
        </Container>
    );
};

function build_link(pretext,link) {
    return (
        <p>{pretext}, please <a href={link}>click me</a>.</p>
    );
}

function build_project(project, side) {

    let side_class = "timeline " + side;

    return (
        <div className={side_class}>
            <div className="card">
                <div className="card-body p-4">
                    <h5 className="fw-bold">{project.title}</h5>
                    <p className="text-muted mb-2 fw-bold">{project.date}</p>
                    {project.image_layout === "horizontal" ? horizontal_layout(project.image, project.description) : vertical_layout(project.image, project.description)}
                    {project.links ? project.links.map((link)=>build_link(link.pretext, link.path)) : null}
                    <p>Skills:{' '}{project.skills.map((tag) => <Tag name={tag} />)}</p>
                </div>
            </div>
        </div>
    );
};

function Showcase() {
    const [projects, set_projects] = useState(null);

    useEffect(()=>{
        set_projects(project_data.map((project, idx) => build_project(project, (idx % 2) === 0 ? "left" : "right")))
    }
        ,[]);

    return (
        <section >
            <div className="container ">
                <div className="main-timeline">
                    {projects}
                </div>
            </div>
        </section>
    );
}

export default Showcase;


