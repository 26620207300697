// Home.js
import React from 'react';

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import { ReactComponent as LinkedIn } from "../components/icons/linkedin.svg"
import { ReactComponent as GitHub } from "../components/icons/github.svg"
import { ReactComponent as PDF } from '../components/icons/acrobat.svg';

// styling
import './Home.css'
import { Link } from 'react-router-dom';

/*
Note to self for styling optimization.
Make class rule for p tags as left align and default everything for center alignment
https://drive.google.com/file/d/1z0G3Edj7NwNkVOY2S-N0BdxyWPIwwzcH/view?usp=drive_link
https://drive.google.com/file/d/1xygJjIkD2S79yGy-wO5vncyqmj3seMsd/view?usp=sharing

*/

const Home = () => {
    return (
        <div className="d-flex justify-content-center" style={{ marginTop: "12px", marginBottom: "12px" }}>
            <Card className='rounded-top-card slider' style={{ padding: "10px", width: '30rem', backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
                <div style={{ alignSelf: "center", textAlign: "center" }}>
                    <Image roundedCircle src="/me.jpg" width="250px" />
                    <h1>Maximillian <span style={{ color: "#ff8000" }}>Machado</span></h1>
                    <p><a href='mailto:maximillian.k.machado@gmail.com' style={{ textDecoration: "none", color: "black" }}>MAXIMILLIAN.K.MACHADO@GMAIL.COM</a></p>
                    <p>
                        <Link to="https://drive.google.com/file/d/1xygJjIkD2S79yGy-wO5vncyqmj3seMsd/view?usp=sharing">
                            <PDF className="d-inline-block icon" style={{ fill: "#ff0000" }} />
                            Resume
                        </Link> | {' '}
                        <Link to="https://drive.google.com/file/d/1z0G3Edj7NwNkVOY2S-N0BdxyWPIwwzcH/view?usp=drive_link">
                            <PDF className="d-inline-block icon" style={{ fill: "#ff0000" }} />
                            CV
                        </Link> | {' '}
                        <Link to="https://www.linkedin.com/in/maximillian-machado/">
                            <LinkedIn className="d-inline-block icon" style={{ fill: "#0077b5" }} />
                            LinkedIn
                        </Link> | {' '}
                        <Link to="https://github.com/imi-mach">
                            <GitHub className="d-inline-block icon" style={{ fill: "black" }} />
                            GitHub
                        </Link>
                    </p>
                </div>

                <hr></hr>
                <h3 style={{ alignSelf: "center" }}>Welcome! &#128516;</h3>
                <p>
                    I am <b>Maximillian Machado</b> - born and raised in the Sunshine State. As a Cuban-American from South Florida, I take pride in being a part of tech in the financial services workforce. Additionally, I'm an avid enjoyer of food, coffee, and computer science (specifically interpretable deep learning).
                </p>
                <p>Curious to learn more? Check these links out:
                    <li>
                        Academic interests and background info, <Link to="../Bio">click here</Link>.
                    </li>
                    <li>
                        Work, research, and programs, <Link to="../Experiences">click here</Link>.
                    </li>
                    <li>
                        Cool projects I've been around, <Link to="../Projects">click here</Link>.
                    </li>
                    <li>
                        For the beautiful background picture, <Link to="../Background">click here</Link>.
                    </li>
                </p>
            </Card>
        </div>
    );
};

export default Home;
