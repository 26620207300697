// Navigation.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// react-bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ReactComponent as Book } from "./icons/book-bookmark.svg"
import { ReactComponent as Map } from "./icons/map.svg"
import { ReactComponent as Wrench } from "./icons/wrench-simple.svg"
import { ReactComponent as Swirl } from "./icons/pen-swirl.svg"
import { ReactComponent as LinkedIn } from "./icons/linkedin.svg"
import { ReactComponent as GitHub } from "./icons/github.svg"

// styling
import './Navigation.css'

function Navigation(props) {
    const [modalShow, setModalShow] = useState(false);
    const padding = 75;
    return (
        <Container fluid className='w-100'>
            <Navbar fixed={props.isFixed} expand="lg" className="bg-body-tertiary">
                <Container>
                    <Row className='w-100 align-items-center'>
                        <Col className='d-flex justify-content-start'>
                            <Navbar.Brand as={Link} to="/">
                                <Image className='navbar-logo' roundedCircle src="/headshot.jpg" />{' '}
                                Maximillian <b style={{ color: "#ff8000" }}>Machado</b>
                            </Navbar.Brand>
                        </Col>
                        <Col>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Container className="d-flex justify-content-center">
                                    <Nav>
                                        <Nav.Link as={Link} to="/bio">
                                            <Book className='d-inline-block icon' />{' '}
                                            Bio
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/experiences">
                                            <Map className="d-inline-block icon" />{' '}
                                            Experiences
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/projects">
                                            <Wrench className="d-inline-block icon" />{' '}
                                            Projects
                                        </Nav.Link>
                                    </Nav>
                                </Container>

                            </Navbar.Collapse>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <Link to="https://www.linkedin.com/in/maximillian-machado/">
                                <LinkedIn className="d-inline-block icon" style={{ fill: "#0077b5" }} />
                            </Link>
                            <Link to="https://github.com/imi-mach">
                                <GitHub className="d-inline-block icon" style={{ fill: "black" }} />
                            </Link>
                        </Col>

                    </Row>
                </Container>
            </Navbar>
        </Container>
    );
}

export default Navigation;