// Projects.js
import React from 'react';

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import Showcase from '../components/Showcase';

import './Projects.css'

const Projects = () => {
  return (
    <div className="d-flex justify-content-center" style={{ marginTop: "12px", marginBottom: "12px" }}>
      <Card className='slider' style={{ padding: "18px", width: '70rem', backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
        <div style={{ alignSelf: "center", textAlign: "center" }}>
          <h2>Projects</h2>
        </div>
        <Image src="/CollageProjects.png" width="100%" />

        <Showcase />

      </Card>

    </div>
  );
};

export default Projects;